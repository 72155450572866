
import { PROVIDER, SERVICES as S } from "@/config/literals";
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import CreateUi from "@/components/Core/Create/CreateUI.vue";
import EquipmentBrandAutocomplete from "@/components/General/Autocompletes/Equipments/EquipmentBrandAutocomplete.vue";
import { DateSelector } from "prometheus-synced-ui";

import { IThermometerService } from "@/services";
import { lightFormat } from "date-fns";

@Component({
  components: {
    CreateUi,
    EquipmentBrandAutocomplete,
    DateSelector,
  },
})
export default class ThermometerCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  forbiddenSerialNumbers!: string[];

  get thermometerService() {
    return this.container.resolve<IThermometerService>(S.THERMOMETER_SERVICE);
  }

  loading: boolean = false;
  serialNumber: string | null = null;
  equipmentType: string | null = null;
  equipmentBrandId: string | null = null;
  validityDate: Date | null = null;

  isAllowed(serialNumber: string) {
    return (
      _.findIndex(
        this.forbiddenSerialNumbers,
        (v) => v.toUpperCase() == serialNumber.toUpperCase()
      ) != -1
    );
  }

  async create() {
    try {
      this.loading = true;
      const createdThermometer = await this.thermometerService.create({
        serialNumber: this.serialNumber!,
        equipmentType: this.equipmentType!,
        equipmentBrandId: this.equipmentBrandId!,
        validityDate: lightFormat(this.validityDate!, "yyyy-MM-dd"),
      });
      this.$emit("create", createdThermometer);
    } finally {
      this.loading = false;
    }
  }
}
